<template>
  <div>
    <toolbar></toolbar>
    <side-bar></side-bar>
    <core-view></core-view>
  </div>
</template>
<script>
export default {
  data: () => ({
    forICTDongnai: process.env.VUE_APP_IS_FOR_DONGNAI === "true"
  }),
  mounted() {
    const prefix_title = this.forICTDongnai ? "" : "[a' Meeting] - "
    document.title = `${prefix_title}${this.$t("common.appTitle")}`;
  }
}
</script>
<style lang="scss">
</style>
